import { Component, OnInit } from '@angular/core';
import { FragmentBase, FragmentInjectable } from '../fragment-base';
import { CreateIntegrationResponse } from '@services/dws/winery-integrations.service';

@Component({
  selector: 'web-divinea-connection',
  templateUrl: './divinea-connection.component.html',
  styleUrls: ['./divinea-connection.component.scss'],
})
export class DivineaConnectionComponent extends FragmentBase implements OnInit {

  constructor(
    private injector: FragmentInjectable,
  ) { 
    super(injector);
  }

  ngOnInit() {}
}