import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ProductResolverService } from '@services/dws/resolvers/product-resolver.service';
import { ContactsResolverService } from '@services/dws';
import { TransactionalEmailsResolverService } from '@services/dws/resolvers/transactional-emails-resolver.service';
import { TransactionalEmailResolverService } from '@services/dws/resolvers/transactional-email-resolver.service';
import { TemplateHtmlResolverService } from '@services/dws/resolvers/template-html-resolver.service';
import { TemplateHtmlsResolverService } from '@services/dws/resolvers/templates-html-resolver.service';
import { OnlyProGuard } from '@web/app/services/guards/only-pro.guard';
import { AccountResolverService } from '@services/resolvers/account-resolver.service';
import {
  InactiveTransactionalEmailsResolverService
} from '@services/dws/resolvers/inactive-transactional-emails-resolver-service';

const routes: Routes = [
  { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'dashboard', loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule) },
  {
    path: 'reports-dashboard',
    loadChildren: () => import('./pages/reports-dashboard/reports-dashboard.module').then(m => m.ReportsDashboardModule)
  },
  { path: 'homepage', loadChildren: () => import('./pages/homepage/homepage.module').then(m => m.HomepageModule) },
  {
    path: 'availability',
    loadChildren: () => import('./pages/availability/availability.module').then(m => m.AvailabilityModule)
  },
  {
    path: 'experiences',
    loadChildren: () => import('./pages/experiences/experiences.module').then(m => m.ExperiencesModule)
  },
  {
    path: 'experiences/:experienceId',
    loadChildren: () => import('./pages/experience-detail/experience-detail.module').then(m => m.ExperienceDetailModule)
  },
  {
    path: 'company-management',
    loadChildren: () => import('projects/web/src/app/pages/company-management/company-management.module').then(m => m.CompanyManagementModule)
  },
  {
    path: 'discount',
    canLoad: [],
    loadChildren: () => import('projects/web/src/app/pages/discount-code/discount-code.module').then(m => m.DiscountCodeModule)
  },
  {
    path: 'discount/:discountCodeId',
    canLoad: [],
    loadChildren: () => import('projects/web/src/app/pages/discount-code/new-discount-code/new-discount-code.module').then(m => m.NewDiscountCodeModule)
  },
  { path: 'contacts', canLoad: [OnlyProGuard], loadChildren: () => import('./pages/contacts/contacts.module').then(m => m.ContactsModule) },
  {
    path: 'contacts/:contactId/:companyId',
    canLoad: [OnlyProGuard],
    loadChildren: () => import('./pages/contact-detail/contact-detail.module').then(m => m.ContactDetailModule)
  },
  { path: 'winery-contact-import',
    canLoad: [OnlyProGuard],
    loadChildren: () => import('./pages/winery-contact-import/winery-contact-import-module').then(m => m.WineryContactImportModule) },
  { path: 'tags', canLoad: [OnlyProGuard], loadChildren: () => import('./pages/tags/tags.module').then(m => m.TagsModule) },
  { path: 'tags/new', canLoad: [OnlyProGuard], loadChildren: () => import('./pages/tags/new-tag/new-tag.module').then(m => m.NewTagModule) },

  { path: 'segments', canLoad: [OnlyProGuard] , loadChildren: () => import('./pages/segments/segments.module').then(m => m.SegmentsModule) },
  {
    path: 'segments/new',
    canLoad: [OnlyProGuard],
    loadChildren: () => import('./pages/segments/new-segment/new-segment.module').then(m => m.NewSegmentModule)
  },
  {
    path: 'segments/:segmentId/edit',
    canLoad: [OnlyProGuard],
    loadChildren: () => import('./pages/segments/new-segment/new-segment.module').then(m => m.NewSegmentModule)
  },

  {
    path: 'tags/new/:tagId',
    canLoad: [OnlyProGuard],
    loadChildren: () => import('./pages/tags/new-tag/new-tag.module').then(m => m.NewTagModule)
  },
  { path: 'marketing', canLoad: [OnlyProGuard], loadChildren: () => import('./pages/marketing/marketing.module').then(m => m.MarketingModule) },
  {
    path: 'account-detail',
    loadChildren: () => import('./pages/account-detail/account-detail.module').then(m => m.AccountDetailModule)
  },
  { path: 'orders', canLoad: [OnlyProGuard], loadChildren: () => import('./pages/orders/orders.module').then(m => m.OrdersModule) },
  { path: 'orders/import', canLoad: [OnlyProGuard], loadChildren: () => import('./pages/orders-import/orders-import.module').then(m => m.OrdersImportModule) },
  {
    path: 'orders/:orderId',
    canLoad: [OnlyProGuard],
    loadChildren: () => import('./pages/order-detail/order-detail.module').then(m => m.OrderDetailModule)
  },
  {
    path: 'products/:productId/orders/:orderId',
    canLoad: [OnlyProGuard],
    loadChildren: () => import('./pages/order-detail/order-detail.module').then(m => m.OrderDetailModule),
    data: {
      returnToProduct: true
    }
  },
  { path: 'products', loadChildren: () => import('./pages/products/products.module').then(m => m.ProductsModule) },
  { path: 'products/import', canLoad: [OnlyProGuard], loadChildren: () => import('./pages/products-import/products-import.module').then(m => m.ProductsImportModule) },

  {
    path: 'products/new',
    loadChildren: () => import('./pages/products/new-product/new-product.module').then(m => m.NewProductModule)
  },
  {
    path: 'products/:productId/edit',
    canLoad: [OnlyProGuard],
    loadChildren: () => import('./pages/products/new-product/new-product.module').then(m => m.NewProductModule),
    resolve: {
      product: ProductResolverService
    }
  },
  {
    path: 'products/:productId',
    canLoad: [OnlyProGuard],
    loadChildren: () => import('./pages/products/product-detail/product-detail.module').then(m => m.ProductDetailModule)
  },
  {
    path: 'product-categories',
    canLoad: [OnlyProGuard],
    loadChildren: () => import('./pages/product-categories/product-category.module').then(m => m.ProductCategoryModule)
  },
  {
    path: 'product-categories/new',
    canLoad: [OnlyProGuard],
    loadChildren: () => import('./pages/product-categories/new-product-category/new-product-category.module').then(m => m.NewProductCategoryModule)
  },
  {
    path: 'product-categories/:id',
    canLoad: [OnlyProGuard],
    loadChildren: () => import('./pages/product-categories/new-product-category/new-product-category.module').then(m => m.NewProductCategoryModule)
  },
  {
    path: 'pos',
    canLoad: [OnlyProGuard],
    loadChildren: () => import('./pages/pos/pos.module').then(m => m.POSModule)
  },
  {
    path: 'pos/:orderId',
    canLoad: [OnlyProGuard],
    loadChildren: () => import('./pages/pos/pos.module').then(m => m.POSModule)
  },
  {
    path: 'pos/self-order/:preorderId',
    canLoad: [OnlyProGuard],
    loadChildren: () => import('./pages/pos/pos.module').then(m => m.POSModule)
  },
  {
    path: 'gifts',
    loadChildren: () => import('./pages/gifts/gifts.module').then(m => m.GiftsModule)
  },
  {
    path: 'reservations',
    loadChildren: () => import('./pages/reservations/reservations.module').then(m => m.ReservationsModule)
  },
  {
    path: 'registration/:companyId',
    loadChildren: () => import('./pages/registration/registration.module').then(m => m.RegistrationModule)
  },
  {
    path: 'privacy',
    loadChildren: () => import('./pages/privacy/privacy.module').then(m => m.PrivacyModule)
  },
  {
    path: 'registration/:companyId/:contactId',
    loadChildren: () => import('./pages/registration/registration.module').then(m => m.RegistrationModule),
    resolve: {
      contact: ContactsResolverService
    }
  },
  {
    path: 'registration/:companyId/qr-code/:qrcodeId',
    loadChildren: () => import('./pages/registration/registration.module').then(m => m.RegistrationModule),
  },
  {
    path: 'qr-code/:companyId/:qrcodeId',
    loadChildren: () => import('./pages/qr-code/qr-code.module').then(m => m.QrCodeModule)
  },
  {
    path: 'unsubscribe/:companyId/:contactId/:contactEmail',
    loadChildren: () => import('./pages/unsubscribe/unsubscribe.module').then(m => m.UnsubscribeModule)
  },
  {
    path: 'registration-code',
    loadChildren: () => import('./pages/registration-code/registration-code.module').then(m => m.RegistrationCodeModule)
  },
  {
    path: 'preorders',
    loadChildren: () => import('./pages/preorders/preorders.module').then(m => m.PreordersModule)
  },
  {
    path: 'preorders-code',
    loadChildren: () => import('./pages/preorders-code/preorders-code.module').then(m => m.PreordersCodeModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: 'transactional-emails',
    loadChildren: () => import('@web/app/pages/transactional-emails/transactional-emails.module').then(m => m.TransactionalEmailsModule),
    resolve: {
      transactionalEmails: TransactionalEmailsResolverService,
      templatesHTMLs: TemplateHtmlsResolverService,
      login: AccountResolverService,
      alreadyInactiveTrigger: InactiveTransactionalEmailsResolverService },
    data: { admin: false }
  },
  {
    path: 'transactional-emails/:transactionalEmailId',
    loadChildren: () => import('@web/app/pages/transactional-email-detail/transactional-emails-detail.module').then(m => m.TransactionalEmailsDetailModule),
    data: { admin: false },
    resolve: {
      //transactionalEmails: TransactionalEmailsResolverService,
      transactionalEmail: TransactionalEmailResolverService
    }
  },
  {
    path: 'template-htmls/:templateHtmlType',
    loadChildren: () => import('@web/app/pages/template-html-detail/template-html-detail-routing.module').then(m => m.TemplateHtmlDetailRoutingModule),
    data: { admin: false },
    resolve: {
      templatesHTMLs: TemplateHtmlsResolverService,
      templatesHTML: TemplateHtmlResolverService,
      login: AccountResolverService
    }
  },
  {
    path: 'reset-password/:token',
    loadChildren: () => import('@web/app/pages/reset-password/reset-password.module').then(m => m.ResetPasswordModule),
  },
  {
    path: 'integrations',
    canLoad: [OnlyProGuard],
    loadChildren: () => import('@web/app/pages/integrations/integrations.module').then(m => m.IntegrationsModule),
    resolve: {
      login: AccountResolverService
    }
  },  
  {
    path: 'integrations/stripe-config',
    canLoad: [OnlyProGuard],
    loadChildren: () => import('@web/app/pages/integrations/integration-config/stripe-config/stripe-config.module').then(m => m.StripeConfigModule),
    resolve: {
      login: AccountResolverService
    }
  },
  {
    path: 'developers',
    canLoad: [OnlyProGuard],
    loadChildren: () => import('@web/app/pages/developers/developers.module').then(m => m.DevelopersModule),
    resolve: {
      login: AccountResolverService
    }
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
