import { Component, OnInit } from '@angular/core';
import { GoogleAuthService } from '@services/dws/google-auth.service';
import { FragmentBase, FragmentInjectable } from '../fragment-base';

@Component({
  selector: 'web-google-calendar-connection',
  templateUrl: './google-calendar-connection.component.html',
  styleUrls: ['./google-calendar-connection.component.scss'],
})
export class GoogleCalendarConnectionComponent extends FragmentBase implements OnInit {

  constructor(
    private googleAuthService: GoogleAuthService,
    private injector: FragmentInjectable,
  ) { 
    super(injector);
  }

  ngOnInit() {}

  get isLinked() {
    return !!this.wineryIntegration &&
    this.wineryIntegration.connected &&
    this.wineryIntegration.integrationData && (
      !this.wineryIntegration.integrationData.array ||
      !!this.wineryIntegration.integrationData.array[this.index]
    );
  }

  public get connectEnabled() {
    return !this.isLinked;
  }

  public get removeEnabled() {
    return this.isLinked;
  }

  public async connect() {
    const credentials = await this.googleAuthService.authorize().toPromise();
    if (credentials.error == "popup_closed_by_user") return;
    return credentials;
  }

  clickHandler() {
    this.clickEventSubject.next({
      type: GoogleCalendarConnectionComponent,
      index: this.index,
      action: 'connect'
    });
  }
}