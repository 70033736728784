import { Component, OnInit } from '@angular/core';
import { OutlookAuthService } from '@services/dws/outlook-auth.service';
import { LoadingComponent } from '@web/app/components/loading/loading.component';
import { FragmentBase, FragmentInjectable } from '../fragment-base';

@Component({
  selector: 'web-outlook-calendar-connection',
  templateUrl: './outlook-calendar-connection.component.html',
  styleUrls: ['./outlook-calendar-connection.component.scss'],
})
export class OutlookCalendarConnectionComponent extends FragmentBase implements OnInit {

  constructor(
    private outlookAuthService: OutlookAuthService,
    private injector: FragmentInjectable,
  ) {
    super(injector);
  }

  ngOnInit() {}

  get isLinked() {
    return !!this.wineryIntegration &&
    this.wineryIntegration.connected &&
    this.wineryIntegration.integrationData && (
      !this.wineryIntegration.integrationData.array ||
      !!this.wineryIntegration.integrationData.array[this.index]
    );
  }

  public get connectEnabled() {
    return !this.isLinked;
  }

  public get removeEnabled() {
    return this.isLinked;
  }

  public async connect() {
    let closedWindow = null;
    LoadingComponent.loading = true;
    const credentials = await this.outlookAuthService.authorize()
      .catch((error) => {
        closedWindow = error;
        LoadingComponent.loading = false;
      })
    LoadingComponent.loading = false;
    if (closedWindow) return;

    return credentials;
  }

  clickHandler() {
    this.clickEventSubject.next({
      type: OutlookCalendarConnectionComponent,
      index: this.index,
      action: 'connect'
    });
  }
}