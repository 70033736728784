import { WineryIntegration } from "@interfaces/dws/integration";
import { Subject } from "rxjs";
import { Injectable, Type } from "@angular/core";

export interface FragmentClickEvent {
    type: Type<any>,
    index: number,
    action: 'connect' | 'sync' | 'remove'
};

@Injectable()
export class FragmentInjectable {
  wineryIntegration!: WineryIntegration;
  wineryId!: string;
  index!: number;
  clickEventSubject!: Subject<FragmentClickEvent>;
}

/**
 * Base class that all fragments must extend.
 * Getters must be public since the parent component accesses them to show the correct actions in the header.
 * Methods are the actions that can be performed on the integration, implemented in the fragment to apply the strategy pattern.
 * Methods must not store the updated integration data, but rather just return the collected data.
 * To trigger one of the actions from inside the fragment, publish an event on the clickEventSubject
 * provided by the injector: the parent component will subscribe to this event, call the method on the fragment
 * and update the integration data.
 * P.S. If you want a function to be executed after an action declare a public function in the fragment named '<action>Callback'
 * Example: connectCallback(response: CreateIntegrationResponse) { } will be called after the connection action is ended successfully.
 */
export class FragmentBase {    
    protected wineryId: string;
    protected wineryIntegration?: WineryIntegration;
    protected index: number;
    protected clickEventSubject!: Subject<FragmentClickEvent>;

    constructor(
        injector: FragmentInjectable,
    ) {
        this.wineryId = injector.wineryId;
        this.wineryIntegration = injector.wineryIntegration;
        this.index = injector.index;
        this.clickEventSubject = injector.clickEventSubject;
    }


    public get connectEnabled(): boolean {
        return false;
    }
    public get syncEnabled(): boolean {
        return false;
    }
    public get removeEnabled(): boolean {
        return false;
    }


    public connect(): Promise<any> {
        return Promise.resolve();
    }

    public sync(): Promise<any> {
        return Promise.resolve();
    }

    public remove(): Promise<any> {
        return Promise.resolve();
    }
}