<mat-toolbar>
    <h1>{{'Advanced Filter' | translate}}</h1>

    <div class="actions">
      <button mat-icon-button (click)="closeClicked()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
</mat-toolbar>


<div class="filter-wrapper">
    <form [formGroup]="form">

        <div class="field-wrapper" *ngFor="let ad of advancedFilter">
            <label [for]="ad.id">
                {{ ad.label | translate }}
            </label>
            <input
                matInput
                [id]="ad.id"
                [formControlName]="ad.id"
                [type]="ad.type"
                *ngIf="ad.type == 'text' || ad.type == 'number' || ad.type == 'email'"
            />

            <input
                matInput
                id="{{ad.id}}-gte"
                [formControl]="shh(form!.get(ad.id)!.get('gte'))"
                type="text"
                mask="separator.2"
                thousandSeparator="."
                [ngStyle]="{ width: '50%' }"
                *ngIf="ad.type === 'currency'"
                placeholder="{{'Min' | translate}}"
            />

            <input
                matInput
                id="{{ad.id}}-lte"
                [formControl]="shh(form!.get(ad.id)!.get('lte'))"
                type="text"
                mask="separator.2"
                thousandSeparator="."
                [ngStyle]="{ width: '50%' }"
                *ngIf="ad.type === 'currency'"
                placeholder="{{'Max' | translate}}"
            />

            <div *ngIf="ad.type==='presence'"
                 (click)="openQrModal(shh(form!.get(ad.id)), ad.options)"
            >
                <input
                        [value]="chosenPresenceOptions(shh(form!.get(ad.id)))"
                    readonly
                />
            </div>

            <div *ngIf="ad.type === 'date'" class="date-picker-input">
                <input matInput [formControlName]="ad.id" [matDatepicker]="picker" (focus)="picker.open()">
                <mat-datepicker #picker></mat-datepicker>
            </div>

            <mat-form-field *ngIf="ad.type === 'date-interval'" class="example-form-field" style="margin-bottom: -1.25em"    >
                <mat-date-range-input
                    (click)="campaignOnePicker.open()"
                    [rangePicker]="campaignOnePicker">
                    <input [formControl]="shh(form!.get(ad.id)!.get('start'))" matStartDate readonly>
                    <input [formControl]="shh(form!.get(ad.id)!.get('end'))" matEndDate readonly>
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>
                <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
            </mat-form-field>

            <mat-form-field *ngIf="ad.type === 'multiselect-date'"
                            class="multiselect-date"
                            appearance="fill"
                            (click)="datepicker.open()">
                <mat-chip-list #chipList>
                    <mat-chip *ngFor="let date of dates"
                              (removed)="removeDate(date, ad.id)">
                        {{date | date : 'dd/MM/yyyy'}}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                    <input [formControlName]="ad.id"
                           (dateChange)="dateChanged($event)"
                           [matDatepicker]="datepicker"
                           [matChipInputFor]="chipList" hidden>
                </mat-chip-list>
                <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
                <mat-datepicker #datepicker [dateClass]="dateClass"></mat-datepicker>
            </mat-form-field>


            <mat-select
                *ngIf="ad.type === 'select' || ad.type == 'multiselect'"
                [formControlName]="ad.id"
                [id]="ad.id"
                [compareWith]="ad.compare || compareFn"
                [multiple]="ad.type == 'multiselect'"
            >
                <mat-option [value]="null" *ngIf="ad.type !== 'multiselect'"></mat-option>
                <mat-option *ngFor="let status of ad.options" [value]="status">
                    <ng-container *ngIf="ad.name">{{ad.name(status)}}</ng-container>
                    <ng-container *ngIf="!ad.name && status.name">{{status.name}}</ng-container>
                    <ng-container
                        *ngIf="!ad.name && !status.name">{{ status | translatable | firstupper }}</ng-container>
                </mat-option>
            </mat-select>
        </div>

    </form>

    <div class="btn-container">
        <button (click)="resetClicked()" mat-stroked-button style="margin-right: 10px">{{ 'Remove' | translate }}
        </button>
        <button mat-stroked-button type="submit" (click)="submitClicked()">{{ 'Search' | translate }}</button>
    </div>
</div>