import { Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import {
  DWSIntegration,
  DWSIntegrationConnector,
  WineryIntegration,
} from "@interfaces/dws/integration";
import { Login } from "@services/accounts.service";
import { WineryIntegrationsService } from "@services/dws/winery-integrations.service";
import { access } from "@util/route/access";
import { tr } from "@util/tr";
import { AddEditIntegrationComponent } from "@web/app/components/dialogs/add-edit-integration/add-edit-integration.component";
import { toast } from "@web/util/toast";
import { forkJoin } from "rxjs";
import { observe } from "@web/util/loading/loading";
import { locale } from "@util/locale";

@Component({
  selector: "web-integrations",
  templateUrl: "./integrations.component.html",
  styleUrls: ["./integrations.component.scss"],
})
export class IntegrationsComponent implements OnInit {
  categoriesControl = new FormControl();
  statusControl = new FormControl();
  searchTerm = new FormControl();
  categories: String[] = [];
  integrations: DWSIntegrationConnector[] = [];
  wIntegrations: WineryIntegration[] = [];
  isNewForDays = 90;

  login: Login;
  lang = locale().locale;

  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private wineryIntegrationsService: WineryIntegrationsService
  ) {
    this.login = access(route).data("login");
  }

  ngOnInit() {
    this.loadPage();
  }

  loadPage() {
    observe(forkJoin([
      this.wineryIntegrationsService.getIntegrations(),
      this.wineryIntegrationsService.getWineryIntegrations(this.login.company.id),
    ])).subscribe({
      next: ([integrations, wineryIntegrations]) => {
        this.integrations = integrations;
        this.prepareCategoriesAndIntegrations(integrations);
        this.wIntegrations = wineryIntegrations;
      },
      error: (err) => {
        console.error("Error fetching integrations:", err);
        toast(tr("An error occurred. Try later"));
      },
    });
  }

  prepareCategoriesAndIntegrations(integrations: DWSIntegrationConnector[]) {
    const categories = integrations
      .map((integration) => integration.integrationType || "NO CAT")
      .filter((value, index, self) => self.indexOf(value) === index);
    this.categories = categories;
    this.integrations = integrations;
  }

  get filteredCategories() {
    return (
      this.categoriesControl.value?.length > 0
        ? this.categoriesControl.value || this.categories
        : this.categories
    )?.filter((c: string) => this.filteredIntegrations(c)?.length > 0).sort((a: string, b: string) => a.localeCompare(b));
  }

  filteredIntegrations(category: string) {
    return this.integrations.filter(
      (integration) =>
        (integration.integrationType === category ||
          !!!integration.integrationType ||
          !!!category) &&
        (!this.searchTerm.value ||
          integration.integrationName
            .toLowerCase()
            .includes(this.searchTerm.value.toLowerCase()) ||
          integration.integration?.name
            .toLowerCase()
            .includes(this.searchTerm.value.toLowerCase())) &&
        (!!!this.statusControl.value ||
          this.statusControl.value ===
            this.isEnabledInThisWinery(integration.integration!))
    ).sort((a, b) => a.integrationName.localeCompare(b.integrationName));
  }

  generateBadge(
    integration: DWSIntegration
  ): [string | undefined, string | undefined] {
    if (this.isInstalledInThisWinery(integration)) {
      if(this.isEnabledInThisWinery(integration) && this.isConnected(integration)){
        return [tr("Installed"), "#68CC45"];
      } else {
        return [tr("Disconnected"), "#eb4646"];
      }
    }

    if (!integration.active) {
      if (new Date(integration?.createdAt!) > new Date()) {
        return [tr("Next"), "#F5BA58"];
      }
    } else {
      const futureDate = new Date();
      futureDate.setDate(futureDate.getDate() - (this.isNewForDays));
    
      if (new Date(integration?.createdAt!) > futureDate) {
        return [tr("New"), "#47A7FF"];
      }
    }
    
    return [undefined, undefined];
  }

  getBadgeColor(integration: DWSIntegration): string | undefined {
    return this.generateBadge(integration)[1];
  }

  getBadgeText(integration: DWSIntegration): string | undefined {
    return this.generateBadge(integration)[0];
  }

  toggleActiveState(integration: DWSIntegration, active: boolean) {
    this.updateIntegrationOrOutputError(
      { wineryId: this.login.company.id,integrationId: integration.id, integration: integration } as WineryIntegration,
      active
    );
  }

  isConnected(integration: DWSIntegration): boolean {
       var founded = this.wIntegrations.find(
      (wInt) =>
        wInt && wInt.integration && wInt.integration?.id === integration!.id
    );

    return (founded?.connected || false);
  }

  isInstalledInThisWinery(integration: DWSIntegration): boolean {
    var founded = this.wIntegrations.find(
      (wInt) =>
        wInt && wInt.integration && wInt.integration?.id === integration!.id
    );
    return !!founded;
  }

  isEnabledInThisWinery(integration: DWSIntegration): boolean {
    var founded = this.wIntegrations.find(
      (wInt) =>
        wInt && wInt.integration && wInt.integration?.id === integration!.id && wInt.disabled === false
    );
    return !!founded;
  }

  installOrManage(integration: any) {
    let wineryIntegration = this.wIntegrations.find(
      (wi) => wi?.integrationId === integration.integration.id
    );
    this.dialog
      .open(AddEditIntegrationComponent, {
        minWidth: 1000,
        panelClass: "no-overflow",
        data: { wineryId: wineryIntegration?.wineryId, integration, wineryIntegration },
      })
      .afterClosed()
      .subscribe((data) => {
        this.loadPage();
      });
  }

  updateIntegrationOrOutputError(
    integration: WineryIntegration,
    active: boolean
  ) {
    if (active) {
      observe(
      this.wineryIntegrationsService
        .connectIntegration(this.login.company.id, integration)
      ).subscribe({
          next: () => {
            toast(tr("Integration saved successfully"));
          },
          error: (err) => {
            console.error("Error updating integration:", err);
            toast(tr("An error occurred. Try later"));
            this.loadPage();
          },
          complete: () => {
            this.loadPage();
          }
        });
    } else {
      observe(
      this.wineryIntegrationsService
        .disconnectIntegration(this.login.company.id, integration)
      ).subscribe({
          next: () => {
            toast(tr("Integration saved successfully"));
          },
          error: (err) => {
            console.error("Error updating integration:", err);
            toast(tr("An error occurred. Try later"));
            this.loadPage();
          },
          complete: () => {
            this.loadPage();
          }
        });
    }
  }
}
