import { NgModule } from "@angular/core";
import { OutlookCalendarConnectionComponent } from "./outlook-calendar-connection/outlook-calendar-connection.component";
import { GoogleCalendarConnectionComponent } from "./google-calendar-connection/google-calendar-connection.component";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { MatButtonModule } from "@angular/material/button";
import { StripeConnectionComponent } from "./stripe-connection/stripe-connection.component";
import { EnologiaConnectionComponent } from "./enologia-connection/enologia-connection.component";
import { ManavaConnectionComponent } from "./manava-connection/manava-connection.component";
import { DivineaConnectionComponent } from "./divinea-connection/divinea-connection.component";


@NgModule({
  declarations: [
    OutlookCalendarConnectionComponent,
    GoogleCalendarConnectionComponent,
    StripeConnectionComponent,
    EnologiaConnectionComponent,
    ManavaConnectionComponent,
    DivineaConnectionComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    MatButtonModule,
  ],
  exports: [
    OutlookCalendarConnectionComponent,
    GoogleCalendarConnectionComponent,
    StripeConnectionComponent,
    EnologiaConnectionComponent,
    ManavaConnectionComponent,
    DivineaConnectionComponent,
  ],
})
export class FragmentsModule {}