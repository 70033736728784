import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { resource } from "../../util/resource";
import { DWSIntegration, DWSIntegrationConnector, WineryIntegration } from "../../interfaces/dws/integration";

@Injectable({
  providedIn: "root",
})
export class WineryIntegrationsService {
  constructor() {}

  connectIntegration(
    wineryId: string,
    integration: WineryIntegration
  ): Observable<unknown> {
    return resource("crm://integrations/active")
      .params({ wineryId: wineryId })
      .put(integration);
  }

  disconnectIntegration(
    wineryId: string,
    integration: WineryIntegration
  ): Observable<unknown> {
    return resource("crm://integrations/deactivate")
      .params({ wineryId: wineryId  })
      .put(integration);
  }

  upsertIntegration(
    wineryId: string,
    integration: WineryIntegration
  ): Observable<CreateIntegrationResponse> {
    return resource("crm://integrations")
      .params({ wineryId: wineryId })
      .post(integration);
  }

  deleteIntegration(
    wineryId: string,
    integration: WineryIntegration
  ): Observable<unknown> {
    return resource("crm://integrations/delete")
      .params({ wineryId: wineryId })
      .post(integration);
  }

  getWineryIntegrations(
    wineryId: string
  ): Observable<WineryIntegration[]> {
    return resource("crm://integrations/winery/wineryId")
      .params({
        wineryId: wineryId
      })
      .get<WineryIntegration[]>();
  }

  getWineryIntegration(
    wineryId: string,
    integrationCode: string
  ): Observable<WineryIntegration | undefined> {
    return resource("crm://integrations/winery/wineryId/integrationCode")
      .params({wineryId, integrationCode})
      .get<WineryIntegration | undefined>();
  }

  getIntegrations(
  ): Observable<DWSIntegrationConnector[]> {
    return resource("crm://integrations/for-wineries")
      .params({})
      .get<DWSIntegrationConnector[]>();
  }

  getIntegrationsForExperiences(
  ): Observable<DWSIntegration[]> {
    return resource("crm://integrations/for-experiences")
      .params({})
      .get<DWSIntegration[]>();
  }

  getIntegrationSingle(
    integrationId: string
  ): Observable<WineryIntegration> {
    return resource(`crm://integrations/integrationId`)
      .params({})
      .get<WineryIntegration>();
  }

  isConnected(
    wineryId: string,
    integrationId: string
  ): Observable<boolean> {
    return resource("crm://integrations/is-connected")
      .params({ wineryId: wineryId, integrationId: integrationId })
      .get<boolean>();
  }

  getSingleIntegration(
    wineryId: string,
    integrationId: string
  ): Observable<DWSIntegrationConnector> {
    return resource(`crm://integrations/integration/integrationId`)
      .params({wineryId, integrationId})
      .get<DWSIntegrationConnector>();
  }

  syncIntegration(
    wineryId: string,
    integration: WineryIntegration
  ): Observable<unknown> {
    return resource("crm://integrations/sync")
      .post(integration);
  }
}

export interface CreateIntegrationResponse {
  wineryIntegration: WineryIntegration;
  connected: boolean;
  success: boolean;
  message: string;
  extraData: { [key: string]: any };
}