import { Component, OnInit } from '@angular/core';
import { FragmentBase, FragmentInjectable } from '../fragment-base';
import { CreateIntegrationResponse } from '@services/dws/winery-integrations.service';

@Component({
  selector: 'web-stripe-connection',
  templateUrl: './stripe-connection.component.html',
  styleUrls: ['./stripe-connection.component.scss'],
})
export class StripeConnectionComponent extends FragmentBase implements OnInit {

  constructor(
    private injector: FragmentInjectable,
  ) { 
    super(injector);
  }

  ngOnInit() {}

  get isLinked() {
    return !!this.wineryIntegration &&
    this.wineryIntegration.connected &&
    this.wineryIntegration.integrationData;
  }

  public get connectEnabled() {
    return !this.isLinked;
  }

  public get removeEnabled() {
    return this.isLinked;
  }

  public async connect() {
    return {};
  }

  clickHandler(action: 'connect' | 'sync' | 'remove') {
    this.clickEventSubject.next({
      type: StripeConnectionComponent,
      index: this.index,
      action
    });
  }

  public connectCallback(response: CreateIntegrationResponse) {
    if (response.extraData.url) {
      window.open(response.extraData.url, "_self");
    }
  }
}