import { resource } from '@util/resource';
import { Observable } from 'rxjs';
import { Injectable} from '@angular/core';
import { Override, ExperienceAvailability, OverrideCount, AvailabilityReq } from "../../interfaces/dws/experience-availabilities";
import * as moment from "moment";
import { ExperienceSlots } from '@interfaces/dws/experience-slots';

@Injectable({
  providedIn: 'root'
})

export class ExperienceAvailabilityService {
  constructor() {}

  getAvailabilities(wineryId: string, startDate: Date, endDate: Date): Observable<ExperienceAvailability[]> {
    return resource('crm://experiences/availabilities')
        .params({
          wineryId: wineryId,
          startDate: startDate.toISOString().split("T")[0],
          endDate: endDate.toISOString().split("T")[0]
        })
        .get<ExperienceAvailability[]>()
  }

  getAvailabilitiesWithFilter(wineryId: string, startDate: Date, endDate: Date, filter: any): Observable<ExperienceAvailability[]> {
    return resource('crm://experiences/availabilities/filter')
        .params({
          wineryId: wineryId,
          startDate: startDate.toISOString().split("T")[0],
          endDate: endDate.toISOString().split("T")[0]
        })
        .post<ExperienceAvailability[]>({filters: filter});
  }

  resumeAvailabilitiesWithFilter(wineryId: string, startDate: Date, endDate: Date, filter: any): Observable<{day:string, availabilityStatus: string}[]> {
    return resource('crm://experiences/availabilities/resume')
        .params({
          wineryId: wineryId,
          startDate: startDate.toISOString().split("T")[0],
          endDate: endDate.toISOString().split("T")[0]
        })
        .post<any[]>({filters: filter});
  }

  getAvailabilitiesByExperience(experienceId: string): Observable<ExperienceSlots[]> {
    return resource('crm://experiences/experienceId/availabilities')
        .params({experienceId})
        .get<ExperienceSlots[]>()
  }

  getAvailabilitiesWithFill(wineryId: string | undefined, startDate: string, endDate: string, onlyErrors: boolean): Observable<ExperienceAvailability[]> {
    return resource('crm://admin/experiences/availabilities-with-fill')
        .headers({ 'x-admin-authorization': '1' })
        .params({
          wineryId: wineryId,
          startDate: startDate,
          endDate: endDate,
          onlyErrors: onlyErrors ? true : false
        })
        .get<ExperienceAvailability[]>()
  }

  override(o: Override): Observable<unknown> {
    if (o.isEvent) {
      return resource('crm://experiences/experienceId/event-availabilities/override')
          .params({experienceId: o.experience.id})
          .post(o);

    } else if (o.isSingleSlot) {
      return resource('crm://experiences/experienceId/single-slot-availabilities/override')
        .params({experienceId: o.experience.id})
        .post(o);

    } else {
      return resource('crm://experiences/experienceId/availabilities/override')
          .params({experienceId: o.experience.id})
          .post(o);
    }
  }

  moveAvailability(wineryId: string, o: AvailabilityReq[]): Observable<AvailabilityReq[]> {
    return resource('crm://experiences/availabilities/move')
        .post({wineryId: wineryId, availabilities: o});
  }

  registerAvailability(wineryId: string, o: AvailabilityReq[]): Observable<AvailabilityReq[]> {
    return resource('crm://experiences/availabilities/register')
      .post({wineryId: wineryId, availabilities: o});
  }

  overrideCount(o: OverrideCount): Observable<unknown> {
    if (o.isEvent) {
      return resource('crm://admin/experiences/experienceId/event-availabilities/override-count')
      .headers({ 'x-admin-authorization': '1' })
        .params({experienceId: o.experienceId})
        .post(o);

    } else if (o.isSingleSlot) {
      return resource('crm://admin/experiences/experienceId/single-slot-availabilities/override-count')
      .headers({ 'x-admin-authorization': '1' })
        .params({experienceId: o.experienceId})
        .post(o);

    } else {
      return resource('crm://admin/experiences/experienceId/availabilities/override-count')
          .headers({ 'x-admin-authorization': '1' })
          .params({experienceId: o.experienceId})
          .post(o);
    }
  }

  haveAvailability(request: HaveAvailability): Observable<boolean> {
    const time = moment(request.time, "HH:mm").format("HHmmss")
    const date = moment(request.date, "YYYY-MM-DD").format("YYYYMMDD")
    return resource('crm://experiences/experienceId/availabilities/date-time/exists')
        .params({date: date, time: time, experienceId: request.experienceId})
        .get<boolean>();
  }

  haveAvailabilityWithCount(request: HaveAvailability): Observable<ExperienceAvailability> {
    const time = moment(request.time, "HH:mm").format("HHmmss")
    const date = moment(request.date, "YYYY-MM-DD").format("YYYYMMDD")
    return resource('crm://experiences/experienceId/availabilities/date-time/count')
        .params({date: date, time: time, experienceId: request.experienceId})
        .get<ExperienceAvailability>();
  }
}

export interface HaveAvailability {
  date: string;
  time: string;
  experienceId: string;
}