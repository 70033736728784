import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OnlyAuthorizedGuard } from '@services/guards/only-authorized.guard';
import { HostCompanyResolverService } from '@services/resolvers/host-company-resolver.service';
import { IntegrationsComponent } from '@web/app/pages/integrations/integrations.component';

const routes: Routes = [{
  path: '',
  component: IntegrationsComponent,
  canActivate: [OnlyAuthorizedGuard], // REMOVE FOR BASIC
  resolve: {
    company: HostCompanyResolverService
  }
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class IntegrationsRoutingModule { }
