import { Component, Inject, ViewChild, Type, Injector, QueryList, ViewChildren } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {
  DWSIntegration,
  DWSIntegrationConnector,
  WineryIntegration,
} from "@interfaces/dws/integration";
import { WineryIntegrationsService } from "@services/dws/winery-integrations.service";
import { observe } from "@web/util/loading/loading";
import { ga } from "@util/ga";
import { tr } from "@util/tr";
import { toast } from "@web/util/toast";
import { MatStepper } from "@angular/material/stepper";
import { of, Subject } from "rxjs";
import { OutlookCalendarConnectionComponent } from "./fragments/outlook-calendar-connection/outlook-calendar-connection.component";
import { GoogleCalendarConnectionComponent } from "./fragments/google-calendar-connection/google-calendar-connection.component";
import { EnologiaConnectionComponent } from "./fragments/enologia-connection/enologia-connection.component";
import { ManavaConnectionComponent } from "./fragments/manava-connection/manava-connection.component";
import { NgComponentOutlet } from "@angular/common";
import { FragmentClickEvent, FragmentInjectable } from "./fragments/fragment-base";
import { catchError } from "rxjs/operators";
import { StripeConnectionComponent } from "./fragments/stripe-connection/stripe-connection.component";
import { DomSanitizer } from "@angular/platform-browser";
import { DivineaConnectionComponent } from "./fragments/divinea-connection/divinea-connection.component";

const fragmentNameMap: { [key: string]: Type<any> } = {
  'OutlookCalendarConnectionComponent': OutlookCalendarConnectionComponent,
  'GoogleCalendarConnectionComponent': GoogleCalendarConnectionComponent,
  'StripeConnectionComponent': StripeConnectionComponent,
  'EnologiaConnectionComponent': EnologiaConnectionComponent,
  'ManavaConnectionComponent': ManavaConnectionComponent,
  'DivineaConnectionComponent': DivineaConnectionComponent,
};

@Component({
  selector: "web-add-edit-integration",
  templateUrl: "./add-edit-integration.component.html",
  styleUrls: ["./add-edit-integration.component.scss"],
})
export class AddEditIntegrationComponent {
  integration: DWSIntegrationConnector;
  wineryIntegration: WineryIntegration;
  wineryId: string;
  form!: FormArray;
  isEdit = false;
  finishMessage = tr("Integration saved successfully");

  get finishMessageSafe() {
    return this.sanitizer.bypassSecurityTrustHtml(this.finishMessage);
  }

  @ViewChild(MatStepper) stepper!: MatStepper;
  @ViewChildren(NgComponentOutlet) dynamicFragments!: QueryList<NgComponentOutlet>;

  dynamicComponent: Type<any> | null = null;
  fragmentInjectors: Injector[];
  clickEventSubject = new Subject<FragmentClickEvent>();

  getRegistryType(key: string) {
    let val: any =
      this.integration.integrationDataRegistry![key as keyof Object] ||
      this.integration.integrationOptionsRegistry![key as keyof Object];

    if (val.startsWith("enum:")) {
    }

    return "text";
  }

  constructor(
    private fb: FormBuilder,
    private dlg: MatDialogRef<AddEditIntegrationComponent>,
    private integrationsService: WineryIntegrationsService,
    @Inject(MAT_DIALOG_DATA)
    data: {
      integration: DWSIntegrationConnector;
      wineryIntegration: WineryIntegration;
      wineryId: string;
    },
    injector: Injector,
    private sanitizer: DomSanitizer,
  ) {
    this.wineryId = data.wineryId;
    this.integration = data.integration;
    this.wineryIntegration = data.wineryIntegration;

    ga.track("Integration Management");

    this.fragmentInjectors = Array.from(
      { length: (this.wineryIntegration?.integrationData?.array?.length || 0) + 1 },
      (_, index) => Injector.create({
        providers: [{ provide: FragmentInjectable, useValue: {
          wineryIntegration: this.wineryIntegration,
          wineryId: this.wineryId,
          index,
          clickEventSubject: this.clickEventSubject,
        } }],
        parent: injector,
      })
    );

    this.createForm(this.integration, this.wineryIntegration?.integrationData?.array?.length);
    this.patchForm(this.wineryIntegration);
    this.loadDynamicComponent();

    this.clickEventSubject.subscribe(({type, index, action}) => {
      this.actionClicked(null, index, action);
    });
}

  loadDynamicComponent() {
    const registry = this.integration.integrationDataRegistry || {};
    for (const [key, value] of Object.entries(registry)) {
      if (value === 'fragment') {
        if (fragmentNameMap[key]) {
          this.dynamicComponent = fragmentNameMap[key];
          break;
        } else {
          console.error(`Component ${key} not found in component map.`);
        }
      }
    }
  }

  objectKeysWithoutFragments(obj: Record<string, any>): string[] {
    if (Object.values(obj ?? {}).find(val => val === 'fragment')) {
      return [];
    } else {
      return Object.keys(obj);
    }
  }

  createForm(integration: DWSIntegrationConnector, existingAccountNum: number) {
    let formObject = this.objectKeysWithoutFragments(
      integration.integrationDataRegistry || {}
    )
      .sort((a, b) => a.localeCompare(b))
      .reduce((acc, key) => {
        acc[key] = [null, Validators.required];
        return acc;
      }, {} as Record<string, any>);
  
    this.objectKeysWithoutFragments(integration.integrationOptionsRegistry || {})
      .sort((a, b) => a.localeCompare(b))
      .forEach((key) => {
        formObject[key] = [null];
      });
  
    this.form = this.fb.array(
      Array.from({ length: (existingAccountNum || 0) + 1 }, () => this.fb.group(formObject))
    );
  }

  patchForm(wineryIntegration?: WineryIntegration) {
    if (!wineryIntegration) return;
    this.isEdit = true;

    for (let i = 0; i < (wineryIntegration.integrationData?.array?.length || 0); i++) {
      var formFields = Object.keys((this.form.at(i) as FormGroup).controls);

      formFields.forEach((field) => {
        if (
          wineryIntegration.integrationData.array[i] &&
          wineryIntegration.integrationData.array[i][field as keyof WineryIntegration] !== undefined
        ) {
          let vall =
            wineryIntegration.integrationData.array[i][field as keyof WineryIntegration];
          this.form.at(i).get(field)?.setValue(vall);
        }

        if (
          wineryIntegration.integrationOptions.array[i] &&
          wineryIntegration.integrationOptions.array[i][field as keyof WineryIntegration] !== undefined
        ) {
          let vall =
            wineryIntegration.integrationOptions.array[i][
              field as keyof WineryIntegration
            ];
          this.form.at(i).get(field)?.setValue(vall);
        }
      });
    }

    if (wineryIntegration.integrationData && !wineryIntegration.integrationData.array) {
      var formFields = Object.keys((this.form.at(0) as FormGroup).controls);

      formFields.forEach((field) => {
        if (
          wineryIntegration.integrationData[field as keyof WineryIntegration] !== undefined
        ) {
          let vall =
            wineryIntegration.integrationData[field as keyof WineryIntegration];
          this.form.at(0).get(field)?.setValue(vall);
        }

        if (
          wineryIntegration.integrationOptions[field as keyof WineryIntegration] !== undefined
        ) {
          let vall =
            wineryIntegration.integrationOptions[
              field as keyof WineryIntegration
            ];
          this.form.at(0).get(field)?.setValue(vall);
        }
      });
    }
      
  }

  generateApiKey(): string {
    const key =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
    return key;
  }

  openExternalLink(integration: DWSIntegration) {
    window.open(integration.linkExternal, "_blank");
  }

  closeClicked() {
    this.dlg.close();
  }

  syncClicked(index: number = 0) {
    observe(
      this.integrationsService.syncIntegration(
        this.integration.integration!.id,
        this.wineryIntegration
      )
    ).subscribe({
      next: (data) => {
        toast(tr("Integration synced successfully"));
      },
      error: (err) => {
        toast(tr("Failed to sync integration"));
      },
    });
  }

  saveClicked(index: number = 0, values?: Record<string, any>, callback?: Function) {
    if (this.form.at(index).invalid) {
      toast(tr("Fill in the required information."));
      return;
    }

    let formValues = values ?? this.form.value;
    if (Array.isArray(formValues) && !values) {
      formValues = formValues[index];
    }

    if (!this.wineryIntegration) {
      this.wineryIntegration = {
        integrationId: this.integration.integration!.id,
        integrationData: this.integration.multiAccount ? {array: []} : {},
        integrationOptions: this.integration.multiAccount ? {array: []} : {},
        integrationName: this.integration.integration!.name,
        integration: this.integration.integration,
        wineryId: this.wineryId,
        disabled: false,
        connected: false,
      };
    }

    var dataToSave = Object.keys(formValues).reduce((acc, key) => {
        if (
          this.integration.integrationDataRegistry &&
          this.integration.integrationDataRegistry.hasOwnProperty(key)
        ) {
          acc[key] = formValues[key];
        }
        return acc;
      }, {} as Record<string, any>)

    if (this.integration.multiAccount && !!this.wineryIntegration.integrationData.array[index]) {
      this.wineryIntegration.integrationData.array[index] = dataToSave;
    } else if (this.integration.multiAccount) {
      this.wineryIntegration.integrationData.array.push(dataToSave);
    } else {
      this.wineryIntegration.integrationData = dataToSave;
    }

    var optionsToSave = Object.keys(formValues).reduce((acc, key) => {
        if (
          this.integration.integrationOptionsRegistry &&
          this.integration.integrationOptionsRegistry.hasOwnProperty(key)
        ) {
          acc[key] = formValues[key];
        }
        return acc;
      }, {} as Record<string, any>);

    if (this.integration.multiAccount && !!this.wineryIntegration.integrationOptions.array[index]) {
      this.wineryIntegration.integrationOptions.array[index] = optionsToSave;
    } else if (this.integration.multiAccount) {
      this.wineryIntegration.integrationOptions.array.push(optionsToSave);
    } else {
      this.wineryIntegration.integrationOptions = optionsToSave;
    }

    observe(this.integrationsService.upsertIntegration(this.wineryId, this.wineryIntegration))
      .pipe(catchError(() => of({success: false, message: ""})))
      .subscribe((data) => {
        if (!data.success) {
          toast(tr("Failed to save integration"));
          return;
        }

        if (data.message) {
          this.finishMessage = data.message;
        }
        this.stepper.next();
        if (callback) callback(data);
      });
  }

  bestName(name: string): string {
    // from camel case to human readable
    return name.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
      return str.toUpperCase();
    });
  }

  removeClicked(index: number = 0, callback?: Function) {
    let v = this.form.value;

    if (!this.integration.multiAccount || this.wineryIntegration?.integrationData?.array?.length <= 1) {
      observe(this.integrationsService.deleteIntegration(
        this.integration.integration!.id,
        this.wineryIntegration
      )).subscribe({
        next: (data) => {
          this.dlg.close();
          if (callback) callback(data);
        },
        error: (err) => {
          toast(tr("Failed to save integration"));
        },
      });
    } else {
      this.wineryIntegration.integrationData.array.splice(index, 1);
      this.wineryIntegration.integrationOptions.array.splice(index, 1);

      observe(this.integrationsService.upsertIntegration(this.wineryId, this.wineryIntegration))
        .pipe(catchError(() => of({success: false, message: ""})))
        .subscribe((data) => {
          if (!data.success) {
            toast(tr("Failed to save integration"));
            return;
          }

          if (data.message) {
            this.finishMessage = data.message;
          }
          this.stepper.next();
        });
    }
  }

  getIsActionButtonVisible(action: 'connect' | 'sync' | 'remove', index: number = 0): boolean {
    var fragment = this.dynamicFragments?.toArray()[index];
    return this.stepper?.selectedIndex === 0 && (
      !fragment || !!fragment['_componentRef'].instance[action + 'Enabled']
    );
  }

  async actionClicked(event: Event | null, index: number = 0, action: 'connect' | 'sync' | 'remove') {
    event?.stopPropagation();
    var fragment = this.dynamicFragments?.toArray()[index];
    var callback = fragment['_componentRef'].instance[action + 'Callback'];

    if (action !== 'remove') {
      // call connect() or sync() on the dynamically loaded fragment
      let values = await fragment['_componentRef'].instance[action]();

      if (values) this.saveClicked(index, values, callback);
    } else {
      this.removeClicked(index, callback);
    }
  }
}