<mat-toolbar>
    <mat-toolbar-row>
      <h1>
        {{ 'Integrations' | translate}}
      </h1>
    </mat-toolbar-row>
</mat-toolbar>

<div class="filter-container">
    <div class="left">
        <mat-select [formControl]="categoriesControl" multiple [placeholder]="'Category' | translate">
            <mat-option *ngFor="let category of categories" [value]="category">{{category | translate}}</mat-option>
        </mat-select>

        <mat-slide-toggle [formControl]="statusControl">{{'Installed' | translate}}</mat-slide-toggle>
    </div>

    <input [formControl]="searchTerm" matInput [placeholder]="'Search Integration' | translate"/>
</div>

<div *ngFor="let category of filteredCategories" class="category-container">
    <p class="category-title">{{ ("integrationTypes." + category) | translate }}</p>
    <div class="flex">
        <mat-card *ngFor="let integration of filteredIntegrations(category)" class="integration-card mat-elevation-z0">
            <mat-card-header>
                <div [ngStyle]="{'background-image': 'url(' + integration.integration.imageUrl + ')'}"
                     class="integration-image mat-elevation-z0"
                     mat-card-avatar></div>
                <mat-card-title>{{ integration.integration.name }}</mat-card-title>
                <span
                        *ngIf="!!!!getBadgeText(integration.integration)"
                        [style]="'background-color: ' + getBadgeColor(integration.integration) + ';'"
                        class="badge"
                >{{ getBadgeText(integration.integration) }}</span>
            </mat-card-header>

            <mat-card-content>
                <p>{{ integration.integration.disclaimerTranslations[lang] }}</p>
            </mat-card-content>

            <mat-card-actions
                    *let="isInstalledInThisWinery(integration.integration) as isInstalled"
                    style="margin-bottom: 0.8rem;"
            >
                <div class="left">
                    <mat-slide-toggle
                            (change)="toggleActiveState(integration.integration, $event.checked)"
                            *ngIf="isInstalled"
                            [checked]="isEnabledInThisWinery(integration.integration)"
                    ></mat-slide-toggle>
                    <mat-icon
                            *ngIf="isInstalled && isEnabledInThisWinery(integration.integration) && !isConnected(integration.integration)"
                            [matTooltip]="'A technical error occurred, please contact support' | translate"
                            style="color: #eb4646;"
                    >error
                    </mat-icon>
                </div>

                <button
                        (click)="installOrManage(integration)"
                        *ngIf="isConnected(integration.integration) || integration.integration.active"
                        [ngClass]="{'install': !isInstalled, 'manage': isInstalled}"
                        mat-stroked-button
                >
                    {{ (isInstalled ? 'Manage' : 'Install') | translate }}
                </button>
            </mat-card-actions>
        </mat-card>
    </div>
</div>

<div *ngIf="!filteredCategories?.length" class="category-container" style="text-align: center;">
    <p class="category-title">{{ 'No integration to show' | translate }}</p>
</div>