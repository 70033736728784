<div class="add-edit-integration">
  <button (click)="closeClicked()" class="closeBtn" mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>
  <div class="create-"></div>
  <div style="padding: 20px">
    <div class="integration-create">
      <div class="field-wrapper">
        <div style="padding: 40px 40px 20px 40px">
          <img
            [src]="integration.integration?.imageUrl"
            class="mediumImage"
            mat-card-lg-image
            style="width: unset; height: unset"
          />
        </div>
      </div>
      <mat-horizontal-stepper>
        <mat-step>
          <div *ngIf="!isEdit">
            <div
              style="
                font-family: Poppins;
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
                text-align: left;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
                padding: 0 40px 20px;
              "
            >
              <div
                style="
                  font-family: Poppins;
                  font-size: 20px;
                  font-weight: 600;
                  line-height: 30px;
                  text-align: left;
                  text-underline-position: from-font;
                  text-decoration-skip-ink: none;
                  padding-bottom: 0.2rem;
                "
              >
                {{ integration.integration?.name }}
              </div>
                <ng-container *ngIf="!dynamicComponent">
                    {{ "Inserisci qui sotto i dati forniti da " | translate }}
                    {{ integration.integration?.name }}
                </ng-container>
            </div>
          </div>

          <ng-container *ngIf="integration.multiAccount; else defaultWrapper">
            <mat-accordion>

              <ng-container *ngFor="let group of form.controls; index as i">

                  <mat-expansion-panel>
                  <mat-expansion-panel-header>
                      <mat-panel-title>{{ (wineryIntegration?.integrationData.array || [])[i]?.name || ('Add' |
                          translate) }}
                      </mat-panel-title>

                      <mat-panel-description style="justify-content: flex-end; gap: 5px;">
                      <mat-icon
                              (click)="actionClicked($event, i, 'connect')"
                              *ngIf="dynamicComponent && getIsActionButtonVisible('connect', i)"
                        [matTooltip]="'Connect' | translate"
                      >add
                      </mat-icon>
                      <mat-icon
                              (click)="actionClicked($event, i, 'sync')"
                              *ngIf="dynamicComponent && getIsActionButtonVisible('sync', i)"
                        [matTooltip]="'Sync' | translate"
                      >sync</mat-icon>
                          <mat-icon [matTooltip]="'Delete' | translate"
                                    (click)="actionClicked($event, i, 'remove')"
                                    *ngIf="dynamicComponent && getIsActionButtonVisible('remove', i)"
                                    style="color: red;"
                      >delete</mat-icon>
                    </mat-panel-description>
                  </mat-expansion-panel-header>

                  <ng-container *ngTemplateOutlet="contentTemplate; context: {index: i, group: group}"></ng-container>
                  
                </mat-expansion-panel>

              </ng-container>

            </mat-accordion>
          </ng-container>

          <ng-template #defaultWrapper>
            <div style="padding: 20px">
              <ng-container *ngTemplateOutlet="contentTemplate; context: {index: 0, group: form.at(0)}"></ng-container>
            </div>
          </ng-template>

        </mat-step>
        <mat-step>
          <div
                  [innerHTML]="finishMessage | translate"
                  style="
              font-family: Poppins;
              font-size: 18px;
              font-weight: 500;
              line-height: 21px;
              text-align: center;
              text-underline-position: from-font;
              text-decoration-skip-ink: none;
              padding: 0 40px 20px;
            "
          ></div>
        </mat-step>
      </mat-horizontal-stepper>
    </div>

      <ng-container
              *ngTemplateOutlet="
          bottomActionButtonsTemplate;
          context: {visible: !dynamicComponent && !integration.multiAccount}
        "
      ></ng-container>
  </div>
</div>

<!-- -- Templates -- -->

<ng-template #contentTemplate let-group="group" let-index="index">
    <div
            *ngFor="let control of objectKeysWithoutFragments(group.controls)"
      class="field-wrapper"
    >
      <label for="name">
        {{ bestName(control) | translate }}
      </label>
      <input
        [formControl]="group.get(control)"
        [type]="getRegistryType(control)"
        id="control"
        matInput
      />
    </div>

    <ng-container
            *ngTemplateOutlet="bottomActionButtonsTemplate; context: {visible: !dynamicComponent && integration.multiAccount, index: index}"
    ></ng-container>

    <ng-container *ngIf="dynamicComponent">
        <ng-container
                *ngComponentOutlet="dynamicComponent; injector: fragmentInjectors[index]"
        ></ng-container>
    </ng-container>
</ng-template>

<ng-template #bottomActionButtonsTemplate let-index="index" let-visible="visible">
    <div *ngIf="visible" class="actions-wrapper">
        <button
                (click)="saveClicked(index)"
                *ngIf="getIsActionButtonVisible('connect', index)"
                class="sBtn"
                mat-stroked-button
        >
            {{ "Connect" | translate }}
        </button>
        <button
                (click)="syncClicked(index)"
                *ngIf="isEdit && integration.syncEnabled && getIsActionButtonVisible('sync', index)"
                class="sBtn"
                mat-stroked-button
        >
            {{ "Sync" | translate }}
        </button>
        <button
                (click)="removeClicked(index)"
                *ngIf="isEdit && getIsActionButtonVisible('remove', index)"
                class="rmvBtn"
                mat-stroked-button
        >
            {{ "Remove" | translate }}
        </button>
    </div>
</ng-template>